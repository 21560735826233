import router from './router'

export default {
    methods: {
        navigateHome() {
            router.push(
                {
                    name: 'Home'
                }
            )
        }
    }
}