export default {
    pages: {
        frontpage: {
            header: 'Tyvärr, länken har gått ut',
            body: 'Det är inte längre möjligt att komma åt bilden via denna länk.'
        },
        handler: {
            invalidlink: "Länken är inte längre giltig"
        },
        viewer: {
            pictureontheway: 'Bilden är på väg',
            nopicture: 'Ingen bild hittades',
            seegallery: 'Se album'
        }
    },
    components: {
        emailcapturebox: {
            header: 'Få bilden via e-post',
            body: 'Ange din e-postadress för att få bilden när myselfie kommer online.',
            getPicture: 'Hämta bild',
            confirmationHeader: 'Så här',
            confirmationBody: 'Du kommer att få ett e-postmeddelande med en länk till din bild när myselfie kommer tillbaka online',
            privatePolicy: 'Sekretesspolicy',
            privatePolicyDesc: 'Du kan läsa vår integritetspolicy här',
            privatePolicyUrl: 'https://myselfie.dk/pages/datapolitik',
            emailErrorLabel: 'Ogiltig e-post',
            emailEmptyLabel: 'E-postfältet måste fyllas i'
        },
        viewerImage: {
            holdDescription: 'Tryck och håll ned bilden<br>för att spara den',
            deleteBtnText: 'Begär radering',
            deleteBtnTextConfirm: 'Är du säker?',
            deleteBtnTextDone: 'Förfrågan har skickats',
            headerText: 'Förfrågan har\nskickats',
            bodyText: 'Bilden är nu dold. Hyresgästen till selfie bestämmer\nom bilden ska raderas',
            bodyTextSecond: 'eller förbli dold.',
            imageText: 'Bild',
            gifText: 'GIF',
            boomerangText: 'Boomerang'
        }
    },
    buttons: {
        close: 'Stänga'
    },
    globals: {
        close : "Stänga",
        saving: 'Dölj',
        email: 'E-post',
        baseUrl: 'https://myselfie.se'
    }
}
