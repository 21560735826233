export default {
    pages: {
        frontpage: {
            header: 'Sorry, the link has expired.',
            body: 'It is no longer possible to access the image with this link.'
        },
        handler: {
            invalidlink: "The link is no longer valid"
        },
        viewer: {
            pictureontheway: 'The image is on the way',
            nopicture: 'No image was found',
            seegallery: 'View album'
        }
    },
    components: {
        emailcapturebox: {
            header: 'Get the image by email',
            body: 'Enter your email address to get the image when myselfie is online.',
            getPicture: 'Get the image',
            confirmationHeader: 'Success',
            confirmationBody: 'You will receive an email with a link to your image when myselfie is back online',
            privatePolicy: 'Privacy policy',
            privatePolicyDesc: 'You can read more about our privacy policy here',
            privatePolicyUrl: 'https://myselfie.dk/pages/datapolitik',
            emailErrorLabel: 'Invalid email',
            emailEmptyLabel: 'Email is required'
        },
        viewerImage: {
            holdDescription: 'Click and hold the image<br>in order to save it',
            deleteBtnText: 'Request deletion',
            deleteBtnTextConfirm: 'Are you sure?',
            deleteBtnTextDone: 'Request has been sendt',
            headerText: 'Request has\nbeen sendt',
            bodyText: 'The image is now hidden. The tenant of the myselfie decides\nif the image should be deleted',
            bodyTextSecond: 'or remain hidden.',
            imageText: 'Photo',
            gifText: 'GIF',
            boomerangText: 'Boomerang'
        }
    },
    buttons: {
        close: 'Close'
    },
    globals: {
        close: 'Close',
        saving: 'Saving',
        email: 'E-mail',
        baseUrl: 'https://myselfie.com'
    }
}