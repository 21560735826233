import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/viewer/:domain/:order/:file/:type/:onedaybutton/:connectionState',
        name: 'Viewer',
        component: () => import('../views/Viewer/Home.vue'),
        props: route => ({query: route.query.checksum})
    },
    {
        path: '/handler/:domain/:order/:file/:type/:onedaybutton/:connectionState/:shortLived',
        name: 'ShortlivedChecksumHandler',
        component: () => import('../views/Handlers/ShortLivedChecksumHandler.vue'),
        props: route => ({query: route.query.checksum})
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})


export default router
