export default {
    pages: {
        frontpage: {
            header: 'Beklager, linket er udløbet.',
            body: 'Det er ikke længere muligt at tilgå billedet via dette link.'
        },
        handler: {
            invalidlink: "Linket er ikke længere gyldigt"
        },
        viewer: {
            pictureontheway: 'Billedet er på vej',
            nopicture: 'Der blev ikke fundet et billede',
            seegallery: 'Se album'
        }
    },
    components: {
        emailcapturebox: {
            header: 'Få billedet på e-mail',
            body: 'Indtast din e-mailadresse for at få billedet når myselfie kommer online.',
            getPicture: 'Få billede',
            confirmationHeader: 'Sådan',
            confirmationBody: 'Du vil modtage en e-mail med et link til dit billede, når myselfie kommer online igen',
            privatePolicy: 'Privatlivspolitik',
            privatePolicyDesc: 'Du kan læse vores privatlivspolitik her',
            privatePolicyUrl: 'https://myselfie.dk/pages/datapolitik',
            emailErrorLabel: 'Ugyldig email',
            emailEmptyLabel: 'Email feltet skal udfyldes'
        },
        viewerImage: {
            holdDescription: 'Tryk og hold på billedet<br>for at gemme det',
            deleteBtnText: 'Anmod om sletning',
            deleteBtnTextConfirm: 'Er du sikker?',
            deleteBtnTextDone: 'Anmodning afsendt',
            headerText: 'Anmodning\nsendt',
            bodyText: 'Billedet er skjult. Det er op til lejeren af\nmyselfie om billedet skal slettes, vises',
            bodyTextSecond: 'eller forblive skjult.',
            imageText: 'Billede',
            gifText: 'GIF',
            boomerangText: 'Boomerang'
        }
    },
    buttons: {
        close: 'Luk'
    },
    globals: {
        close: 'Luk',
        saving: 'Gemmer',
        email: 'E-mail',
        baseUrl: 'https://myselfie.dk'
    }
}