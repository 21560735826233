export default {
    pages: {
        frontpage: {
            header: 'Entschuldigung, der Link ist abgelaufen.',
            body: 'Über diesen Link ist es nicht mehr möglich, auf das Bild zuzugreifen.'
        },
        handler: {
            invalidlink: "Der Link ist nicht mehr gültig"
        },
        viewer: {
            pictureontheway: 'Das Bild ist unterwegs',
            nopicture: 'Es wurde kein Bild gefunden',
            seegallery: 'Album ansehen'
        }
    },
    components: {
        emailcapturebox: {
            header: 'Holen Sie sich das Bild per E-Mail',
            body: 'Geben Sie Ihre E-Mail-Adresse ein, um das Bild zu erhalten, wenn Mynie online ist.',
            getPicture: 'Holen Sie sich das Bild',
            confirmationHeader: 'Erfolg',
            confirmationBody: 'Sie erhalten eine E-Mail mit einem Link zu Ihrem Bild, wenn Mynie wieder online ist',
            privatePolicy: 'Datenschutz-Bestimmungen',
            privatePolicyDesc: 'Hier können Sie mehr über unsere Datenschutzrichtlinie lesen',
            privatePolicyUrl: 'https://myselfie.dk/pages/datapolitik',
            emailErrorLabel: 'Ungültige E-Mail',
            emailEmptyLabel: 'E-Mail ist erforderlich'
        },
        viewerImage: {
            holdDescription: 'Klicken und halten Sie das Bild<br>um es zu speichern',
            deleteBtnText: 'Löschung beantragen',
            deleteBtnTextConfirm: 'Bist du dir sicher?',
            deleteBtnTextDone: 'Anfrage wurde gesendet',
            headerText: 'Anfrage wurde\ngesendet',
            bodyText: 'Das Bild ist jetzt ausgeblendet. Der Mieter des Mynies entscheidet\nob das Bild gelöscht werden soll',
            bodyTextSecond: 'oder verborgen bleiben.',
            imageText: 'Bild',
            gifText: 'GIF',
            boomerangText: 'Boomerang'
        }
    },
    buttons: {
        close: 'Schließen'
    },
    globals: {
        close: 'Schließen',
        saving: 'Speichern',
        email: 'E-mail',
        baseUrl: 'https://www.myselfiebox.de'
    }
}